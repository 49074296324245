@import url('https://fonts.googleapis.com/css?family=Poppins:200,500');
// @import "animate.css";
// @import "tachyons-outlines";
@import "tachyons";
// @import "react-popupbox.css";


/* :root {
    --primarycolour: black;
  } */

body {
  font-family: 'Poppins', sans-serif;
  font-size: 75%; /* 12px */
}
input {
  line-height: 1.6em;
}
p {
  /* color: #3F527F; */
  color: var(--secondarycolour);
  line-height: 1.6;
  margin: 1em 0;
}
li {
  line-height: 1.6;
  margin: 1em 0;
}
b, strong {
  font-weight: 500;
}
body {
  font-weight: 200;
}
h1,h2,h3,h4,h5,h6 {
  color: var(--tertiarycolour);
}

.shadow-2 { box-shadow: 0px 0px 20px 2px rgba( 100, 100, 100, 0.1 ); }

.site-header {
  /* background: #0053FF;
  height: 90px; */
}
.site-footer {
  background: var(--primarycolour);
}
.bg-light-blue {
  background: #F1F2F6;
}
.bg-blue {
  background: var(--primarycolour);
  /* color: var(--primarycolour); */
}
.bg-dark-blue {
  background: var(--primarycolour);
  position: relative;
  // &:before {
  //   content: '';
  //   display: block;
  //   width: 100%;
  //   height: 100%;
  //   position: absolute;
  //   top: 0;
  //   left: 0;
  //   background: rgba(0,0,0,0.15);
  //   pointer-events: none;
  //   z-index: 0;
  // }

}
.bg-green {
  background: #04DCA7;
}
.bg-navy {
  background: #3F5280;
}
.header-logo {
  width: 105px;
  height: 29px;
  background-image: var(--logoimg);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}


.btn {
  -webkit-appearance: none;
  -webkit-border-radius: 0px;
  background-color: #0051ff;
  border: 0;
  border-radius: 20PX;
  cursor: pointer;
  color: #fff;
  -webkit-font-smoothing: antialiased;
  font-size: 14px;
  font-weight: 500;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 40PX;
  margin: 5PX;
  outline: none;
  position: relative;
  padding: 0 30px;
  text-decoration: none;
  user-select: none;
  vertical-align: middle;
  white-space: nowrap;
  overflow: hidden;
}

.btn:hover {
  background-color: #0146dc;
}

.btn--white {
  background-color: var(--tertiarycolour);
  color: var(--primarycolour);
  &:hover {
    background-color: var(--tertiarycolour);
    color: var(--primarycolour);
  }
}
.btn--white:hover {
  background-color: #FFFFFF;
  color: #0146dc;
}

.btn--red {
  background-color: red;
  color: #FFF;
}
.btn--red:hover {
  background-color: red;
  color: #FFF;
}

.accordion__item-label {
  position: relative;
}

.accordion__item-label:before {
  border-bottom: 1px solid #000;
  border-right: 1px solid #000;
  content: '';
  height: 12px;
  position: absolute;
  right: 1rem;
  top: 50%;
  transform: rotate(45deg);
  transform-origin: 100% -100%;
  width: 12px;
}

.accordion__item input:not(:checked) ~ .accordion__content {
  display: none;
}

.white {
  color: var(--secondarycolour);
}

.white::-webkit-input-placeholder {
    color:    #FFF;
}
.white:-moz-placeholder {
    color:    #FFF;
}
.white::-moz-placeholder {
    color:    #FFF;
}
.white:-ms-input-placeholder {
    color:    #FFF;
}

select {
  font-size: inherit;
	display: block;

  height: 1.5em;
  line-height: 1.5em;

	padding: 0 1.5em 0 1em;
	width: 100%;
	max-width: 100%;
	box-sizing: border-box;
	margin: 0;
	border-radius: 0px;
	-moz-appearance: none;
	-webkit-appearance: none;
	appearance: none;
	/* background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIzMiIgaGVpZ2h0PSIzMiIgdmlld0JveD0iMCAwIDMyIDMyIj48cGF0aCBkPSJNMTYuMDAzIDE4LjYyNmw3LjA4MS03LjA4MUwyNSAxMy40NmwtOC45OTcgOC45OTgtOS4wMDMtOSAxLjkxNy0xLjkxNnoiLz48L3N2Zz4='); */
	background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDIyLjEuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCAzMiAzMiIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgMzIgMzI7IiB4bWw6c3BhY2U9InByZXNlcnZlIj4KPHN0eWxlIHR5cGU9InRleHQvY3NzIj4KCS5zdDB7ZmlsbDojRkZGRkZGO30KPC9zdHlsZT4KPHBhdGggY2xhc3M9InN0MCIgZD0iTTE2LDE4LjZsNy4xLTcuMWwxLjksMS45bC05LDlsLTktOWwxLjktMS45TDE2LDE4LjZ6Ii8+Cjwvc3ZnPgo=');
	background-repeat: no-repeat;
	background-position: right .2em top 50%;
	background-size: 1.2em auto;
}
select::-ms-expand {
	display: none;
}
select:hover {
	border-color: #888;
}
select:focus {
	outline: none;
}
select option {
	font-weight:normal;
}

.radar {
  text-align: center;
}
.radar .scale {
  fill: transparent;
}
.radar .axis {
  transform: scale(1.05);
}
.radar .shape {
  fill-opacity: 0;
}
.radar .shape:hover {
  fill-opacity: 0;
}

button:disabled {
  opacity: .4;
}


/*
Custom Drop-dowm */

.dd-wrapper {
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  position: relative;
}
.dd-header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  line-height: 38px;
  border: 1px solid #dfdfdf;
  border-radius: 3px;
  cursor: default;
  position: relative;
  background-color: #fff;
}
.dd-header span {
  margin-right: 20px;
}
.dd-header-title {
  margin: 2px 20px;
  margin-right: 30px;
}
.angle-down {
  color: #000;
  margin-right: 20px;
}
.dd-list {
  z-index: 10;
  position: absolute;
  width: 100%;
  border: 1px solid #dfdfdf;
  border-top: none;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
  background-color: #fff;
  box-shadow: 0 2px 5px -1px #e8e8e8;
  font-weight: 700;
  padding: 0;
  margin: 0;
  max-height: 215px;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
}
.dd-list-item {
  width: 100%;
  padding: 8px 10px;
  margin: 0;
  cursor: default;
  display: inline-block;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.dd-list-item.selected {
  color: #fff;
  background-color: #ffcc01;
}
.dd-list-item:hover {
  color: #fff;
  background-color: #ffcc01;
}

.ReactModalClose {
  border: none;
  background: transparent;
  appearance: none;
  position: absolute;
  width: 30PX;
  height: 30PX;
  top: 0;
  right: 0;
  margin: 0;
  padding: 0;
  cursor: pointer;
}

.ReactModalClose:hover {
  transform: scale(1.1);
}

.ReactModalClose:before,
.ReactModalClose:after {
  content: '';
  display: block;
  width: 50%;
  height: 4PX;
  background: red;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -25%;
  margin-top: -2PX;
}

.ReactModalClose:before {
  transform: rotate(45deg);
}
.ReactModalClose:after {
  transform: rotate(-45deg);
}

.goal-status {
  display: inline-block;
  width: 12px;
  height: 12px;
  background: transparent;
  border-radius: 50%;
  box-shadow: inset 0 0 0 1px red;

}

.goal-status--complete {
  background: green;
  box-shadow: inset 0 0 0 1px green;
}




.popupbox{
  width:100%;
  height:100%;
  position: fixed;
  top: 0;
  left: 0;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  pointer-events: none;
  z-index: 1000;
};

.popupbox[hidden]{
  display: none;
}

.popupbox-overlay{
  position:fixed;
  top: 0;
  left: 0;
  width:100%;
  height:100%;
  z-index:1002;
  background: none repeat scroll 0% 0% #000;
}

.popupbox-content {
  overflow: hidden;
}

.popupbox-wrapper {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  -ms-flex-direction: column;
  -o-flex-direction: column;
  flex-direction: column;
  z-index: 1003;
  position: relative;
}

.popupbox-titleBar {
  overflow: hidden;
  display: block;
  position: relative;
}

.popupbox-btn--close {
  z-index: 1004;
}

.popupbox[data-title='bottom'] .popupbox-content { order: 1 }
.popupbox[data-title='bottom'] .popupbox-titleBar { order: 2 }

/* ----- default theme ----- */

.popupbox-wrapper {
  border-radius: 3px;
  overflow: hidden;
  max-width: 80%;
  min-width: 300px;
  box-shadow: 0 0 20px rgba(0, 0, 0, .9);
  background-color: white;
}

.popupbox-content {
  padding: 20px 24px 30px;
}

.popupbox-titleBar {
  background-color: #f1f2f6;
  color: #3f527f;
  font-size: 18px;
  padding: 20px 50px 20px 25px;
  text-align: left;
  width: 100%;
  background: #f1f2f6;
  border-bottom: 1px solid #3f527f;
}

.popupbox-btn--close {
  transition: all .5s;
  position: absolute;
  right: 0;
  top: 0;
  color: #c1c1c1;
  background: none;
  border: none;
  outline: none;
}

.popupbox-btn--close:hover {
  color: #000;
}

.popupbox.is-active {
  opacity: 1;
  pointer-events: auto;
}

.popupbox[data-title='bottom'] .popupbox-content { box-shadow: 0 1px 1px rgba(0, 0, 0, .3) }
.popupbox[data-title='bottom'] .popupbox-titleBar { box-shadow: none; border-top: 1px #ccc solid }


/*

  OUTLINES

*/
.outline { outline: 1px solid; }
.outline-transparent { outline: 1px solid transparent; }
.outline-0 { outline: 0; }
@media screen and (min-width: 48em) {
 .outline-ns { outline: 1px solid; }
 .outline-transparent-ns { outline: 1px solid transparent; }
 .outline-0-ns { outline: 0; }
}
@media screen and (min-width: 48em) and (max-width: 64em) {
 .outline-m { outline: 1px solid; }
 .outline-transparent-m { outline: 1px solid transparent; }
 .outline-0-m { outline: 0; }
 .outline-l { outline: 1px solid; }
 .outline-transparent-l { outline: 1px solid transparent; }
 .outline-0-l { outline: 0; }
}
